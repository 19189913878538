.battlesModal {
	z-index: $zindex-modal;
	background-color: var(--color--content--foreground-60);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 100%;
	left: 0;
	visibility: hidden;

	&__container {
		position: absolute;
		background-color: var(--color--content--background);
		top: 100%;
		left: 50%;
		width: 425px;
		min-height: 150px;
		transition: top 0.5s;
		border-radius: 19px;

		@include media-breakpoint-down(xs) {
			top: unset;
			bottom: -100%;
			transition: bottom 0.5s;
			width: 100%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&--open {
		visibility: visible;
		top: 0;

		.battlesModal__container {
			top: 50%;
			transform: translate(-50%, -50%);

			@include media-breakpoint-down(xs) {
				left: 0;
				top: unset;
				bottom: 0;
				transform: unset;
				padding: 12px 0;
			}
		}
	}

	&__content {
		padding: 72px 16px 32px 16px;
	}

	&__main-icon {
		position: absolute;
		top: 0;
		left: 50%;
		width: 160px;
		transform: translate(-50%, -50%);

		> img {
			width: 100%;
			object-fit: contain;
		}
	}

	&__action-button {
		width: 100%;
		max-width: 300px;
	}

	.color--gray {
		color: var(--color--content--foreground-80);
	}

	.color--main {
		color: var(--color--content--foreground);
	}
}
